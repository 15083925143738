import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { theme as defaultTheme } from '@theme';

// import {
//   defaultTheme as defaultThemeStyle
// } from '@styles/theme';
import { createContext, ReactNode, useState } from 'react';

//ThemeName
export enum ThemeNames {
  default = 'default',
  backgroudGrey = 'backgroudGrey',
  backgroudPurple = 'backgroudPurple',
  backgroudWaterBlue = 'backgroudWaterBlue',
  backgroudGreen = 'backgroudGreen',
  backgroudOrange = 'backgroudOrange',
  backgroudFini = 'backgroudFini',
  backgroudWatermelon = 'backgroudWatermelon',
  backgroudWhite = 'backgroudWhite',
}

interface ThemeContextInterface {
  setTheme(themeName: ThemeNames): any;
}

interface ThemeInterfaceProps {
  children: ReactNode | any;
  theme?: any;
}

export const ThemeContext = createContext<ThemeContextInterface>(
  {} as ThemeContextInterface
);

export default function CustomThemeProvider({
  children,
  theme,
}: ThemeInterfaceProps) {
  const [themeState, setThemeState] = useState(theme || defaultTheme);

  function setTheme(themeName: ThemeNames) {
    switch (themeName) {
      case ThemeNames.backgroudGrey:
        setThemeState(
          extendTheme(defaultTheme, {
            styles: {
              global: () => ({
                body: {
                  bg: '#FFFFFF',
                  color: '#000',
                },
              }),
            },
          })
        );
        break;
      case ThemeNames.backgroudPurple:
        setThemeState(
          extendTheme(defaultTheme, {
            styles: {
              global: () => ({
                body: {
                  bgColor: '#8E26CD',
                  color: '#fff',
                },
              }),
            },
          })
        );
        break;
      case ThemeNames.backgroudGreen:
        setThemeState(
          extendTheme(defaultTheme, {
            styles: {
              global: () => ({
                body: {
                  // bgColor:"#00A542",
                  bgGradient:
                    'linear-gradient(180deg, #00A542 36.78%, #00BA22 51.68%)',
                  color: '#fff',
                },
              }),
            },
          })
        );
        break;
      case ThemeNames.backgroudOrange:
        setThemeState(
          extendTheme(defaultTheme, {
            styles: {
              global: () => ({
                body: {
                  // bgColor:"#00A542",
                  bgGradient:
                    'linear-gradient(180deg, #FF951A 36.78%, #FF951A 51.68%)',
                  color: '#fff',
                },
              }),
            },
          })
        );
        break;
      case ThemeNames.backgroudWaterBlue:
        setThemeState(
          extendTheme(defaultTheme, {
            styles: {
              global: () => ({
                body: {
                  // bgColor:"#D84654",
                  bgGradient:
                    'linear-gradient(180deg, #4B5BCA 36.78%, #4B5BCA 51.68%)',
                  color: '#fff',
                },
              }),
            },
          })
        );
        break;
      case ThemeNames.backgroudFini:
        setThemeState(
          extendTheme(defaultTheme, {
            styles: {
              global: () => ({
                body: {
                  // bgColor:"#D84654",
                  bgGradient:
                    'linear-gradient(180deg, #CA4B71 36.78%, #CA4B71 51.68%)',
                  color: '#fff',
                },
              }),
            },
          })
        );
      case ThemeNames.backgroudWatermelon:
        setThemeState(
          extendTheme(defaultTheme, {
            styles: {
              global: () => ({
                body: {
                  // bgColor:"#D84654",
                  bgGradient:
                    'linear-gradient(180deg, #D84654 36.78%, #FF6776 51.68%)',
                  color: '#fff',
                },
              }),
            },
          })
        );
        break;
      case ThemeNames.backgroudWhite:
        setThemeState(
          extendTheme(defaultTheme, {
            styles: {
              global: () => ({
                body: {
                  bgColor: '#fff',
                  color: '#000',
                },
              }),
            },
          })
        );
        break;
      default:
        setThemeState(defaultTheme);
        break;
    }
  }
  return (
    <ThemeContext.Provider value={{ setTheme }}>
      <ChakraProvider theme={themeState}>{children}</ChakraProvider>
    </ThemeContext.Provider>
  );
}
