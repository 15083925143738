import { User } from '@interfaces/db/User';
import logger from '@logger/logger';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import React, { createContext, useEffect } from 'react';
import { Cookies } from 'react-cookie-consent';

interface LoginInfosContextInterface {}

interface LoginInfosInterfaceProps {
  children: React.Component | any;
}

export const LoginInfosContext = createContext<LoginInfosContextInterface>(
  {} as LoginInfosContextInterface
);

export default function LoginInfosProvider({
  children,
}: LoginInfosInterfaceProps) {
  const { data, status } = useSession();

  const router = useRouter();

  const handlerMinutesGetLoginInfos = () => {
    logger.debug(data);
    if (data != null && data.user != null) {
      const { customfields:customFields } = data.user as User;
      // console.log("data.user ",data.user)
      // console.log("customFields",customFields)
      const updatedInfos =
        Cookies.get('updatedInfos') != undefined
          ? JSON.parse(Cookies.get('updatedInfos'))
          : null;

      if((customFields != null &&
        customFields.hasUpdatedInfos != null &&
        customFields.hasUpdatedInfos == true)){
          logger.debug("Login Correto")
      }else if (
        updatedInfos != null &&
        updatedInfos.hasUpdatedInfos != true &&
        (customFields == null ||
          customFields.hasUpdatedInfos == null ||
          customFields.hasUpdatedInfos == false)
      ) {
        router.push('/auth/validate_infos');
      } else if (
        updatedInfos == null &&
        (customFields == null ||
          customFields.hasUpdatedInfos == null ||
          customFields.hasUpdatedInfos == false)
      ) {
        router.push('/auth/validate_infos');
      }
    }
  };

  useEffect(() => {
    if (status === 'authenticated') {
      handlerMinutesGetLoginInfos();
      setInterval(() => {
        handlerMinutesGetLoginInfos();
      }, 30 * 60000);
    }
  }, [status]);

  return (
    <LoginInfosContext.Provider value={{}}>
      {children}
    </LoginInfosContext.Provider>
  );
}
